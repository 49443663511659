<template>
    <div
        class="d-flex flex-column align-items-center justify-content-center p-2"
        style="height: 65vh"
    >
        <img
            class="d-inline-block mb-1 mw-100"
            width="400"
            src="@/assets/imgs/internet.svg"
            alt="no internet"
        />
        <h1 class="h2 mb-4 text-center">No internet connection!</h1>
        <router-link to="/" class="btn btn-brand">
          Back to Home Page
        </router-link>
    </div>
</template>

<script>
export default {
  name: "NetworkError",
};
</script>
